import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {

  obtenerDepositoZona() {      
    var url = ConfigAPI.baseURL + "deposits-zone" + Session.getToken();
    return instance.get(url);        
  },

  obtenerEquivalencesAvailable(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "products-unit-measurement/filter/equivalenceAvailable" + Session.getToken();
    return instance.post(url,params);            
  },  
}

export default servicesAPI;
